import React from 'react';
import { P } from '../styled/GlobalElements';

class ErrorBoundary extends React.Component {
  state = {
    error: false,
  };

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return <P>Sorry, something went wrong.</P>;
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
